.timea-portrait {
    width: 196px;
}

.main-content {
    background-color: white;
    padding-bottom: 20px;
}

.timea-logo {
    width: 196px;
}

.main-text {
    font-family: arial,helvetica neue,helvetica,sans-serif;
    font-size: 21px;
    color: #808080;
}

.additional-wording {
    display: block;
}

.formInputLabel {
    font-family: Arial;
    color: rgb(34, 34, 34);
    font-weight: bold;
}

.socials a+a {
    margin-left: 20px;
}

.socials a {
    color: black;
}

.socials a:hover {
    color: grey;
}

@media screen and (min-width: 991px) {
    .main-content {
        width: 60%;
    }
    .form-input {
        width: 50%;
    }
}  